import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { DishDelivery } from '@enums/dish-delivery.enum';
import { GetSingleOrderPrice } from '@functions/get-single-order-price.function';
import { Order } from '@models/order.model';
import { TranslateModule } from '@ngx-translate/core';
import { DishLabelsComponent } from '@pages/restaurants/_components/dish-labels/dish-labels.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { customCurrency } from '@pipes/custom-currency.pipe';

/**
 * Array of PrimeNG modules used in the cart-item component.
 */
const PRIME_NG_MODULES = [ButtonModule, TooltipModule];

/**
 * Represents a component for ordering a dish.
 */
@Component({
  selector: 'app-cart-item',
  standalone: true,
  imports: [PRIME_NG_MODULES, CurrencyPipe, TranslateModule, SvgIconComponent, DishLabelsComponent, customCurrency],
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemComponent {
  /**
   * Represents a dish in the order.
   */
  order = input.required<Order>();

  /**
   * Indicates whether the component is currently validating.
   */
  validating = input<boolean>(false);

  /**
   * The price of the cart item.
   */
  price = computed(() => GetSingleOrderPrice(this.order()));

  /**
   * Increases the quantity of the cart item.
   * @returns {Observable<Order>} An observable emitting the updated order.
   */
  onIncreaseQuantity = output<Order>();

  /**
   * Decreases the quantity of the cart item.
   * @returns An output event of type `Order`.
   */
  onDecreaseQuantity = output<Order>();

  /**
   * Represents the delivery option for a dish.
   */
  DishDelivery = DishDelivery;
}
