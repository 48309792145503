@if (syncing()) {
  <ng-container [ngTemplateOutlet]="loader"></ng-container>
} @else {
  @if (counter() > 0) {
    @for (error of validationErrors(); track $index) {
      <p-messages
        [value]="[{ severity: 'error', detail: 'Cart.Validation.' + error | translate, icon: 'pi-exclamation-circle' }]"
        [enableService]="false"
        [closable]="false"></p-messages>
    }
    @for (order of finalOrder().orders; track $index) {
      <app-cart-item
        [order]="order"
        [validating]="validating()"
        (onIncreaseQuantity)="cartService.increaseQuantity($event); onTriggerValidateCart$.next()"
        (onDecreaseQuantity)="cartService.decreaseQuantity($event); onTriggerValidateCart$.next()"></app-cart-item>
    }
  } @else {
    <app-no-data
      fontIcon="pi-shopping-cart"
      title="{{ 'Cart.Your cart is empty' | translate }}"
      description="{{ 'Cart.Empty cart description' | translate }}"></app-no-data>
  }
}

<ng-template #loader>
  <div class="flex gap-4 p-3" style="border: 1px solid var(--lighter-gray); border-radius: 9px; background: #fff">
    <div style="flex: 1">
      <p-skeleton width="12rem" height="1rem" styleClass="mb-3"></p-skeleton>
      <p-skeleton width="100%" height="0.75rem" styleClass="mb-3"></p-skeleton>
      <p-skeleton width="5rem" height="1rem" styleClass=""></p-skeleton>
    </div>
    <div style="display: flex; align-items: center">
      <p-skeleton width="6rem" height="2rem"></p-skeleton>
    </div>
  </div>
</ng-template>
