<div class="cart-item">
  <div class="cart-item-body">
    <div class="cart-item-icons">
      <app-dish-labels [labels]="order().dish!.labels"></app-dish-labels>
      @if (order().dish!.delivery === DishDelivery.NON_DELIVERY) {
        <span class="non-delivery" pTooltip="{{ 'RestaurantsPage.Non delivery' | translate }}" tooltipPosition="top">
          <svg-icon class="icon-delivery" src="assets/icons/delivery.svg"></svg-icon>
        </span>
      }
    </div>
    <div class="cart-item-name">
      {{ order().dish!.name }}
    </div>
    <div class="cart-item-additions">
      @if (order().additions?.length) {
        @for (addition of order().additions; track $index; let last = $last) {
          {{ addition.name }}{{ last ? '' : ',' }}
        }
      }
    </div>
    <div class="cart-item-price">{{ price() | customCurrency }}</div>
  </div>
  <div class="cart-item-quantity">
    <p-button
      (onClick)="onDecreaseQuantity.emit(order())"
      [disabled]="validating()"
      [icon]="order().quantity! > 1 ? 'pi pi-minus' : 'pi pi-trash'"
      [rounded]="true"
      [styleClass]="
        'icon-only-button cart-quantity-button ' + (order().quantity! === 1 ? 'cart-quantity-button-remove' : '')
      "
      [text]="true"
      severity="secondary">
    </p-button>
    <div class="cart-item-quantity-value">{{ order().quantity }}</div>
    <p-button
      (onClick)="onIncreaseQuantity.emit(order())"
      [disabled]="validating()"
      [rounded]="true"
      [text]="true"
      icon="pi pi-plus"
      severity="secondary"
      styleClass="icon-only-button cart-quantity-button">
    </p-button>
  </div>
</div>
