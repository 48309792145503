import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, inject, input, output } from '@angular/core';
import { ORDER_ITEMS_ERROR } from '@enums/order-items-error.enum';
import { untilDestroyed } from '@functions/until-destroyed.function';
import { CartItemComponent } from '@modules/cart-item/cart-item.component';
import { NoDataComponent } from '@modules/no-data/no-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { CartService } from '@services/cart.service';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';
import { Subject, debounceTime } from 'rxjs';

/**
 * Array of PrimeNG modules used in the cart-items component.
 */
const PRIME_NG_MODULES = [SkeletonModule];

/**
 * The debounce time (in milliseconds) for quantity change events.
 * This is used to delay the execution of quantity change logic to avoid rapid updates.
 */
const QUANTITY_CHANGE_DEBAUNCE_TIME = 600;

@Component({
  selector: 'app-cart-items',
  standalone: true,
  imports: [PRIME_NG_MODULES, CartItemComponent, NoDataComponent, MessagesModule, TranslateModule, NgTemplateOutlet],
  templateUrl: './cart-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemsComponent implements OnInit {
  /**
   * A function that returns an RxJS operator that automatically unsubscribes from observables
   * when the component is destroyed.
   */
  private readonly untilDestroyed = untilDestroyed();
  /**
   * The cart service used to manage the cart items.
   */
  readonly cartService = inject(CartService);
  /**
   * Represents the orders in the cart.
   */
  finalOrder = computed(() => this.cartService.finalOrder());
  /**
   * The computed value of the cart counter.
   */
  counter = computed(() => this.cartService.counter());
  /**
   * The computed value indicating whether the cart is syncing.
   */
  syncing = computed(() => this.cartService.syncing());
  /**
   * Indicates whether to watch for changes in quantity.
   */
  watchQuantityChange = input<boolean>(false);
  /**
   * Indicates whether the input is being validated.
   */
  validating = input<boolean>(false);
  /**
   * Triggers the validation of the cart.
   */
  onTriggerValidateCart = output<void>();
  /**
   * A subject used to trigger the validation of the cart.
   */
  onTriggerValidateCart$ = new Subject<void>();
  /**
   * An array that holds validation errors.
   */
  validationErrors = input<ORDER_ITEMS_ERROR[]>([]);

  /**
   * Initializes the component.
   * This method is called after the component has been created and initialized.
   * It is a lifecycle hook that is called once, immediately after the first `ngOnChanges`.
   */
  ngOnInit(): void {
    if (this.watchQuantityChange()) {
      this.triggerValidateCart();
    }
  }

  /**
   * Triggers the validation of the cart.
   * This method subscribes to the `onTriggerValidateCart$` observable and emits the `onTriggerValidateCart` event
   * when the debounce time has passed and the value has changed.
   */
  triggerValidateCart(): void {
    this.onTriggerValidateCart$
      .pipe(debounceTime(QUANTITY_CHANGE_DEBAUNCE_TIME), this.untilDestroyed())
      .subscribe(() => this.onTriggerValidateCart.emit());
  }
}
